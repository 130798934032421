<template>
  <v-container>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :loading="loading"
      :items="members"
      show-select
      class="elevation-1"
    >
    <template v-slot:item.Bild="{ item }">
        <div style="width: 40px; height: 40px;" v-html="item.userImg"></div>
    </template>
      <template v-slot:top>
        <v-container>
        <h1>KjG Mitglieder</h1>
        </v-container>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          @click="ClickMail(item)"
        >
          mdi-email
        </v-icon>
      <v-icon
        @click="deleteItem(item)"
      >
        mdi-format-list-checks
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
    props: ["members", "loading"],
    data: () => ({
      selected: [],
      headers: [
        { text: 'Bild', value: 'Bild'},
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'id', value: '_id' },
        { text: 'Actions', value: 'actions', sortable: false },
        ]
    }),
    methods: {
      ClickMail(item) {
        this.$emit('clicked', item)
      }
    }
}
</script>

<style>

</style>