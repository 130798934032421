<template>
  <v-container>
    <v-col>
      <Mail :showDialog="dialog" :MailTo="SelectedMailer" @hidePop="HideDialog"/>
      <MembersList :members="Members" @clicked="EmailClick" :loading="loading"/>
    </v-col>
  </v-container>
</template>

<script>
import MembersList from '../components/Members.vue'
import Mail from '../components/Mail.vue'

import axios from 'axios'
export default {
  components: {
    MembersList,
    Mail
  },
  data: () => ({
    Members: [],
    SelectedMailer: [],
    loading: false,
    dialog: false,
    FetchURL: "https://kjg-api.rezept-zettel.de/api/auth"
  }),
  methods: {
    EmailClick(value) {
      this.dialog = true;
      this.SelectedMailer = value;
    },
    HideDialog(value) {
      this.dialog = value
    },
    fetchMemberlist() {
      this.loading = true
      axios
      .get(this.FetchURL)
      .then(response => {
        this.Members = response.data
        this.loading = false
      })
      .catch((err) => {
        console.log(err)
      })
    }
  },
  created() {
    this.fetchMemberlist()
  }
}
</script>

<style>

</style>