<template>
    <v-row>
    <v-dialog v-model="showDialog">
      <v-card>
        <v-card-title>
          <span class="headline">Email an <strong>{{MailTo.name}}</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  outlined
                  label="Name"
                  v-model="MailTo.name"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  id="mail"
                  outlined
                  label="Email"
                  v-model="MailTo.email"
                ></v-text-field>
              </v-col>
            
              <v-col
                cols="12"
              >
                <v-text-field
                  outlined
                  label="Betreff"
                  v-model="subject"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Email Inhalt"
                  v-model="content"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray darken-1"
            text
            @click="Hide"
          >
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            @click="SendMail()"
          >
            Email senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default ({
    props: ["showDialog", "MailTo"],
    data: () => ({
        name: "",
        email: "",
        subject: "",
        content: "",
    }),
    methods: {
      Hide() {
        this.$emit('hidePop', false)
      },
      SendMail: async function() {
          let Email = document.getElementById("mail").value
          await axios({
              method: "POST",
              url: "https://kjg-api.rezept-zettel.de/api/mail/",
              data: {
                  "email": Email,
                  "subject": this.subject,
                  "text": this.content
              }
          })
          .then(Response => {
              console.log(Response)
              Vue.$toast.open({
                  message: 'Email wurde erfolgreich versendet',
                  type: 'success',
              });
            this.Hide();
          })
          .catch((err) => {
              console.log(err)
              Vue.$toast.open({
                  message: 'Mail nicht versendet versuche es später erneut',
                  type: 'error',
              });
          })
      }
    },
})
</script>
